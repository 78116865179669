#sidebar {
  width: 200px;
  height: 100vh;
}

#sidebar-container {
  color: #000;
  background-color: #313a46;
  grid-template-rows: max-content max-content 1fr;
  display: grid;
  position: fixed;
  left: 0;
}

#sidebar-container .sidebar-top h2 {
  text-align: center;
  color: #8391a2;
  padding: 1.5rem 0 0;
}

#sidebar-container .sidebar-middle {
  grid-gap: 10px;
  padding: 2rem 0 3rem;
  display: grid;
}

#sidebar-container .sidebar-middle a {
  color: #8391a2;
  border-bottom: 1px solid gray;
  align-content: center;
  padding: 1rem 0 1rem 1rem;
  transition: all .2s;
  display: grid;
}

#sidebar-container .sidebar-middle a:hover {
  color: #bccee4;
}

#sidebar-container .sidebar-middle a.is-active {
  color: #fff;
}

/*# sourceMappingURL=index.ff435b81.css.map */
